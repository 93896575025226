import React, { useContext } from 'react';
import ConfigContext from '../../Context/ConfigContext';
import UINCDLetterHead from './UINCDLetterHead';
import DefaultLetterHead from './DefaultLetterHead';
const LetterHead =(props)=>{

     
        const HeaderOptions=()=>{

            switch(process.env.REACT_APP_COMPANY_CODE){

                case "UINCD":
                    return <UINCDLetterHead {...props} />
                default:
                    return <DefaultLetterHead {...props}/>

            }
        }


        return (
            <div>{HeaderOptions()}</div>
        );
    }


export default LetterHead;